import React, { useContext, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { MaterialContext } from "../context/MaterialContext";
import { motion, AnimatePresence } from "framer-motion";
import { circleIcon } from "./Icons";
import "../styles/mobile.css";
import presentationData from '../data/presentationData.json'


const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 300 : -300,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 300 : -300,
      opacity: 0,
    };
  },
};

function Mobile() {
  const { materialData, selected, socket, sendLike } =
    useContext(MaterialContext);

  const [material, setMaterial] = useState([]);
  const [images, setImages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [[page, direction], setPage] = useState([0, 0]);
  const [error, setError] = useState(false);
  const [socketMessage, setSocketMessage ] = useState('')
  const [pageItems, setPageItems ] = useState([])

  const params = useParams();

  useEffect(() => {
    socket.on("receive_message", (data) => {
      console.log("mobile", data.message, data.count, presentationData[0].pages[data.count]);
      setPageItems(data.items)
      setSocketMessage(presentationData[0].pages[data.count])
    });
  }, [socket]);

  console.log(socket)

  useEffect(() => {
    const materialSetter = materialData.find(
      (item) => item.slug === params.slug
    );
    if (materialSetter) {
      setMaterial(materialSetter);
      setImages(materialSetter.gallery_medium);
      setPage([0, 0]);
    }
    setTimeout(() => {
      if (materialSetter === undefined) {
        setError(true);
      }
    }, 4000);
  }, [materialData, params.slug]);

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  function wrap(start, total, iterator) {
    if (direction === 1 && iterator === total) {
      setPage([0, direction]);
      return start;
    }
    if (direction === -1 && page === -1) {
      setPage([total - 1, direction]);
      return total - 1;
    }
    return page;
  }

  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const jumpTo = (index) => {
    setPage([index, 0]);
  };

  const Slideshow = () => (
    <AnimatePresence initial={false} custom={direction}>
      <motion.div
        className="scrollable-image-container"
        key={page}
        style={{ width: "100vw", height: "100%", overflow: "hidden" }}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            paginate(1);
            setLoaded(false);
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1);
            setLoaded(false);
          }
        }}
      >
        <img
          onLoad={() => setLoaded(true)}
          src={images[imageIndex]}
          alt={material.title + imageIndex}
        />
      </motion.div>
    </AnimatePresence>
  );

  const handleSave = (data) => {
    sendLike(data)
  };

  if (material && images.length > 0)
    return (
      <div className="mobile-container">
        <div className="mobile-image-container">
          <div className="vendor">
            <h5>{material.vendor}</h5>
          </div>
          <div className="large-image-radios-box">
            {images.map((img, index) => (
              <div
                key={index}
                data-image={img[index]}
                className="large-image-radios"
                role="button"
                aria-hidden="true"
                onClick={() => jumpTo(index)}
                //onClick={() => handleImageChange(index)}
                style={{
                  background: imageIndex === index ? "#F4FB5680" : "none",
                }}
              ></div>
            ))}
          </div>
          <div className="large-image-container">
            {!loaded && <div className="large-image-loader">{circleIcon}</div>}
            <Slideshow image={images} />
          </div>

          {/* {images.length !== 0 && images.map(image => <li key={image} style={{fontSize:'10px'}}>{image}</li>)} */}
        </div>
        <div className="mobile-info-container">
          <h4>{material.title}</h4>
          <h5>{material.description}</h5>
          <hr />
          <h6>${material.price} / sq.ft.</h6>
          <hr />
         {material.type === "cowhide" && <><h5>Kind Leather</h5></>}
       
          <h6>{material.search && material.search.replace(/,/g, ", ")}</h6>
          <h6>Viewing: {socketMessage.desc}</h6>
          <hr />
          {pageItems.length ? pageItems.map(item => <Link key={item} to={`./${item}`}><h6>{item.replace(/-/g, " ").toUpperCase()}</h6></Link>) : <h6>Not viewing materials.</h6>}
          {selected.includes(material.id) ? (
            <div className="save-selection-button-stored">
              <h5>{material.title} STORED</h5>
            </div>
          ) : (
            <button
              className="save-selection-button"
              onClick={() => handleSave(material.id)}
            >
              <h5>STORE {material.title} </h5>
            </button>
          )}
        </div>
      </div>
    );
  if (error) {
    return (
      <div className="mobile-container">
        <div className="mobile-error">
          <h2>Oh No!</h2>
          <h3> Something went horibly wrong with </h3>
          <h3>
            <span style={{ color: "red" }}>
              {params.slug.replace(/,/g, ", ")}
            </span>
          </h3>
          <h3>Try again</h3>
        </div>
      </div>
    );
  }
  return (
    <div className="mobile-container">
      <div className="large-image-loader">
        <h4
          style={{
            textTransform: "capitalize",
            position: "absolute",
            top: "30%",
            textAlign: "center",
            width: "80%",
          }}
        >
          LOADING UP <br />
          <br /> {params.slug.replace(/-/g, " ").toUpperCase()}
        </h4>
        {circleIcon}
      </div>
    </div>
  );
}

export default Mobile;
