import React from 'react'
import logo from '../images/asiatanLogoWhite.png'

function Header({title}) {
  return (
    <div className="header"
    style={{
        position: 'absolute',
        zIndex: 10,
        left: 0,
        top: 0,
        right: 0,
        pointerEvents: 'none',
        paddingLeft: '16px',
        paddingRight: '16px',
        color:'white'
    }}>
    <img src={logo} alt="Asiatan" height="25"/> 
    <h4>{title}</h4>
  </div>
  )
}

export default Header
