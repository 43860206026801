import React, { useContext, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { circleIcon } from "./Icons";
import kindLogo from "../images/kindLogo.png";
import QRCode from "qrcode";
import { MaterialContext } from "../context/MaterialContext";
import { useCurrentSlide } from "../context/CurrentSlideContext";

var duration = 0.15;
const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
}


var opts = {
  errorCorrectionLevel: "H",
  type: "image/jpeg",
  quality: 0.3,
  margin: 2,
  color: {
    dark: "#000000",
    light: "#F4FB56",
  },
};

function MaterialDisplay({ title, slideData }) {
  const { materialData, changeMaterialUsed, changePageItems, selected } = useContext(MaterialContext);
  const { currentSlide } = useCurrentSlide();

  const [largeImage, setLargeImage] = useState([]);
  const [pageItems, setPageItems] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [error, setError ] = useState(false)

  useEffect(() => {
    if (materialData.length !== 0) {
      
      setImageLoaded(false);
      let largeImageSelect = materialData.filter((item) => item.slug === slideData[0])
      setLargeImage(largeImageSelect);
      changePageItems(slideData);
      QRCode.toDataURL(`https://prez.matlib.co/m/${slideData[0]}`, opts).then(
        setQrCode
      );
      slideData.forEach((title) => {
        let item = materialData.filter((x) => x.slug === title);   
        if(item.length === 1){
            setPageItems((pageItems) => [...pageItems, item[0]]);
            //changeMaterialUsed(item[0].id);      
        }else{
            setError(true)
            //setPageItems((pageItems) => [...pageItems]);
        }
      });
    
    }
    return () => {
      setPageItems([]);
      setLargeImage([]);
    };
    // eslint-disable-next-line
  }, [materialData, slideData, currentSlide]);

  useEffect(() => {
      const materials = pageItems.map(item => item.id)
      if(materials.length > 0){
          changeMaterialUsed(materials)
      }
      // eslint-disable-next-line
  },[pageItems]);

  useEffect(() => {
    setPageItems([]);
    setLargeImage([]);
    setImageIndex(0);
  }, [currentSlide]);

  const handleArticleChange = (data) => {
    if (largeImage[0].id !== data.id) {
        
      setImageLoaded(false);
      setImageIndex(0);
      setLargeImage([data]);
      QRCode.toDataURL(`https://prez.matlib.co/m/${data.slug}`, opts).then(
        setQrCode
      );
    }
  };
  const handleImageChange = (index) => {
      if(index !== imageIndex){
        setImageLoaded(false);
        setImageIndex(index);
      }
  
  };
  if(error) {
      return <h1>Error on page</h1>
  }

  return (
    <>
      {pageItems.length !== 0 ? (
          
        <AnimatePresence>
            <div className="title">
            <h3>{title}</h3>
            {error && <h6 style={{position:'relative', fontSize:'10px'}}>There was an error with one of the materials</h6>}
          </div>
        <motion.div
            key={currentSlide}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            className="slide-container">
          
          <div className="container-large">
            <div className="large-image">
              {largeImage && (
                <img
                  src={largeImage[0].gallery_large[imageIndex]}
                  alt={largeImage[0].title}
                  onLoad={() => setImageLoaded(true)}
                  style={{
                    height: "100%",
                    width: "inherit",
                    filter: `blur(${imageLoaded ? "0px" : "5px"})`,
                  }}
                />
              )}
            </div>

            <div className="large-image-loader">
              {!imageLoaded && circleIcon}
            </div>
            {largeImage[0].type === "cowhide" ? (
              <div className="kind-leather-badge">
                <img src={kindLogo} alt="kind leather" />
              </div>
            ) : null}
            <div className="qr-code">
              <img src={qrCode} alt="qr code" />
            </div>

            <div className="large-info-content" style={{ filter: "blur(0px)" }}>
              {largeImage[0] && (
                <>
                  <h3>{largeImage[0].title.toUpperCase()}</h3>
                  <h4>{largeImage[0].description}</h4>
                  <h4>Status: {largeImage[0].commercial}</h4>
                  <span className="price"> <h4>${largeImage[0].price} / sq.ft.</h4></span>

                  <div
                    style={{ marginTop: "10px" }}
                    className="large-image-radios-box"
                  >
                    {largeImage[0].gallery_large.map((img, index) => (
                      <div
                        key={index}
                        data-image={img[index]}
                        className="large-image-radios"
                        role="button"
                        aria-hidden="true"
                        onClick={() => handleImageChange(index)}
                        style={{
                          background:
                            imageIndex === index ? "#F4FB5680" : "none",
                        }}
                      ></div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="right-sidebar-container">
            {largeImage &&
              pageItems.map((item, index) => (
                <div
                  key={index}
                  className="sidebar-image-container"
                  aria-hidden="true"
                  onClick={() => handleArticleChange(item)}
                  style={{
                    boxShadow:
                      item.id === largeImage[0].id
                        ? "0 0 8px black"
                        : "0 0 3px black",
                    transform:
                      item.id === largeImage[0].id
                        ? "rotateZ(0deg) translateX(-10px)"
                        : "none",
                  }}
                >
                  <div className="sidebar-image-name">
                    <h4>{item.title.toUpperCase()} - selected: {selected.includes(item.id) ? 'yes' : 'no'}</h4> 
                  </div>
                  {item.days_added <= 45 && <div className="added"><h4>NEW</h4></div>}
                  <img src={item.gallery_swatch[0]} alt={item.title} />
                </div>
              ))}
          </div>
        </motion.div>
        </AnimatePresence>
      ) : (<h1>Loading...</h1>)}
     
    </>
  );
}

export default MaterialDisplay;
