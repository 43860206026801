import React, { useContext, useState, useEffect } from 'react'
/// import slideData from '../data/matlibData.json'
import { MaterialContext } from '../context/MaterialContext'
import { motion } from 'framer-motion'
import { closeIcon, circleIcon } from './Icons'

const container = {
    hidden: { opacity: 0},
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      }
    }
  }
  
  const itemz = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y:0 }
  }

    // const closeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    // <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    // </svg>



function MaterialModal({title, slideData}) {

    const { materialData } = useContext( MaterialContext )

    const [ popup, setPopup ] = useState(false);
    const [ itemData, setItemData ] = useState([])
    const [ pageItems, setPageItems ] = useState([])
    const [ imageIndex, setImageIndex ] = useState(0)
    const [ imageLoaded, setImageLoaded ] = useState(false)
    const [ articleIndex, setArticleIndex ] = useState(0)
    const [ error, setError ] = useState(false)


    useEffect(() => {
        if(materialData.length !== 0){
          slideData.forEach((title) => {
            let item = materialData.filter(x => x.slug === title )
            if(item.length === 1){
                setPageItems((pageItems) => [...pageItems, item[0]]) 
            }else{
                setError(true)
                setPageItems((pageItems) => [...pageItems, ])
            }
              
          })
        }
      }, [materialData, slideData]);

      useEffect(() => {
          setPageItems([]);
          setPopup(false)
      }, [title]);

      const handleImageChange = (index) => {
        setImageLoaded(false);
        setImageIndex(index)
    }

    const PopUp = () => {
        if(popup){
            return (
                <div className="modal-background" onClick={() => setPopup(false)}>
                   <motion.div 
                   initial={{opacity: 1, y: 0}}
                   animate={{ opacity:1, y: 0 }}
                   exit={{ opacity: 0, y:0 }}
                   transition={{ ease: "easeOut", duration: 0.05 }}
                   className="modal-window"  onClick={event => event.stopPropagation()}>
                      <span className="modal-close" onClick={() => setPopup(false)}>{closeIcon}</span>
                     
                       <div className="modal-window-image">
                           {itemData && <img src={itemData.gallery_medium[imageIndex]} onLoad={() => setImageLoaded(true)} style={{filter: `blur(${imageLoaded ? '0px' : '5px'})`}} alt={itemData.title}/>}
                           <div className="large-image-loader">{!imageLoaded && circleIcon}</div>

                       </div>
                        <div className="large-info-content" style={{filter: 'blur(0px)'}}>
                {itemData &&
                    <>
                        <h3>{itemData.title.toUpperCase()}</h3>
                        <h4>{itemData.description}</h4>
                        <div style={{marginTop:'10px'}} className="large-image-radios-box">
                            {itemData.gallery_large.map((img, index) =>  <div key={index} data-image={img[index]} className="large-image-radios" role = "button" aria-hidden="true" onClick={() => handleImageChange(index)} 
                            style={{background: imageIndex === index ? '#F4FB5680' : 'none'}}></div>)}
                        </div>
                       <span className="price"><h4>${itemData.price} / sq.ft.</h4></span>
                        
                    </>}
                </div>
                       {articleIndex + 1 !== pageItems.length && <div className="modal-next" onClick={() => handleNext()}>&#9658;</div>}
                   </motion.div>
                </div>
            )
        }else{
            return null;
        }
       
    }

    const handlePopUp = (item, i) => {
        setItemData(item)
        setPopup(true)
        setImageLoaded(false)
        setImageIndex(0);
        setArticleIndex(i)
    }
    const handleNext = () => {
        const count = pageItems.length
        let article = pageItems[articleIndex + 1]
        let index = articleIndex + 1;
        if(index !== count){
            handlePopUp(article, index)
        }
    }

      

    return (
        <>
        
        {pageItems.length !== 0 &&
        <motion.div className="page-array"
        initial="hidden"
        animate="show"
        variants={container}
        ><div className="title"><h3>{title}</h3> {error && <h6 style={{position:'relative', fontSize:'10px'}}>There was an error with one of the materials</h6>}</div>
       
             <PopUp />
            {pageItems.map((item, i) => 
            <motion.div  variants={itemz} className="feature-image" key={item.id+i} onClick={() => handlePopUp(item, i)}>
                <div className="feature-image-title"><h4>{item.title}</h4></div>
                <img src={item.gallery_swatch[0]} alt={item.title}/>
            </motion.div>)}
           
        </motion.div>}
        </>
    )
}

export default MaterialModal
