import React, { useContext, useState } from "react";
import presentationData from "../data/presentationData.json";
import { useCurrentSlide } from "../context/CurrentSlideContext";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { questionIcon, arrayIcon } from "./Icons";
import { MaterialContext } from "../context/MaterialContext";
import { closeIcon } from './Icons'

function Footer() {
  const { materialData, selected, clearSelected} = useContext(MaterialContext);
  const { currentSlide, setSlide } = useCurrentSlide();
  const [pop, setPop] = useState(false);

  const location = useLocation();
  const match = useRouteMatch("/m/:slug");

  const pageIndex = currentSlide;

  const MobilePop = () => {
    return (
      <>
        <div className="mobile-pop" onClick={() => setPop(false)}>
        {selected.length !== 0 && <button className="save-selection-button" onClick={() => clearSelected()}><h5>Clear {selected.length} {selected.length > 1 ? 'items' : 'item'}</h5></button>}
          <h5>Stored Items</h5>
         {selected.map(item => <Link onClick={() => setPop(false)} className="mobile-overlay-items" to={`/m/${materialData.find(element => element.id === item).slug}`}  key={item} ><h5>{materialData.find(element => element.id === item).title}</h5></Link>)}
          <div className="mobile-pop-close" onClick={() => setPop(false)}>{closeIcon}</div>
         
        </div>
        
      </>
    );
  };

  const handleLink = (pageNo) => {
    if (currentSlide !== pageNo) {
      setSlide(pageNo);
    }
  };
  const { pages } = presentationData[0];

  if (match) {
    return (
      <>
        {pop && <MobilePop />}
        <div
          className="footer"
          style={{
            position: "absolute",
            zIndex: 1,
            left: 0,
            bottom: 0,
            right: 0,
            pointerEvents: "none",
            paddingLeft: "16px",
            paddingRight: "16px",
            display: "flex",
          }}
        >
          {selected.length >= 1 ? (
            <h6 className="footer-items" onClick={() => setPop(!pop)}>
              view {selected.length} Stored{" "}
              {selected.length > 1 ? "items" : "item"}
            </h6>
          ) : (
            <h6>No stored items yet</h6>
          )}
        </div>
      </>
    );
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <div
        className="footer"
        style={{
          position: "absolute",
          zIndex: 1,
          left: 0,
          bottom: 0,
          right: 0,
          pointerEvents: "none",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        {/* {steps.length !== 0 ? <div className="footer-radios-box">{(currentStep + 1) / steps.length * 100}%</div> : null} */}
        {location.pathname === "/" ? (
          <motion.div
            key="expanded"
            initial={{ opacity: 0, duration: 2 }}
            animate={{ opacity: 1, duration: 2 }}
            exit={{ opacity: 0, duration: 2, delay: 1, x: 200 }}
            className="footer-radios-box"
          >
            {pages.map((page, index) => (
              <div
                key={index}
                role="button"
                aria-hidden="true"
                className="footer-radios"
                data-page={page.type}
                onClick={() => handleLink(index)}
                style={{
                  background: index <= pageIndex ? "#F4FB5680" : "none",
                  cursor: index === pageIndex ? "default" : "pointer",
                }}
              >
                <div className="progress-letters">{page.type}</div>
              </div>
            ))}

            <div className="presentation-array">
              <Link to={{ pathname: "/materials" }}>{arrayIcon}</Link>
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="collapsed"
            initial={{ opacity: 0, duration: 2 }}
            animate={{ opacity: 1, duration: 2 }}
            exit={{ opacity: 0, duration: 2, delay: 1 }}
            className="footer-radios-box"
          >
            <Link to={{ pathname: "/" }} className="footer-return-link">
              Back to Presentation
            </Link>
          </motion.div>
        )}
        <div className="footer-question">
          <Link to={{ pathname: "/directions" }}>{questionIcon}</Link>
        </div>
        <div className="footer-slide-title">{pages[currentSlide].desc}</div>
      </div>
    </AnimatePresence>
  );
}

export default Footer;
