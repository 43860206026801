import React, { useState, useEffect, createContext, useContext} from "react";
import { io } from 'socket.io-client'
import { CurrentSlideContext } from "./CurrentSlideContext";

export const MaterialContext = createContext([]);

const MaterialContextProvider = (props) => {

    const {currentSlide} = useContext(CurrentSlideContext)
    const socket = io.connect('https://matlib.net', {path: "/node/socket.io", transports: ["websocket", "polling"]});
    const room = "jordan_prez"
    socket.on("connect_error", () => {
      // revert to classic upgrade
      socket.io.opts.transports = ["polling", "websocket"];
    });

    const joinRoom = () => {
        socket.emit("join_room", room)
    }

    useEffect(() => {
    joinRoom();
    });

  const localStore = JSON.parse(localStorage.getItem("selected"));

  const [materialData, setMaterialData] = useState([]);
  const [materialUsed, setMaterialUsed] = useState([]);
  const [selected, setSelected] = useState(localStore ? localStore : []);
  const [itemData, setItemData] = useState([]);
  const [pageItems, setPageItems ] = useState([]);

  useEffect(() => {
    localStorage.setItem("selected", JSON.stringify(selected));
    // eslint-disable-next-line
  }, [localStore]);

  useEffect(() => {
    socket.on("receive_likes", (data) => {
      if(!selected.includes(data.like)){
        setSelected([...selected, data.like]);
    }
    });
    // eslint-disable-next-line 
  }, [socket]);

  const value = {
    materialData,
    materialUsed,
    selected,
    localStore,
    socket,
    pageItems,
    sendLike: (data) => {
      if(data){
        const likeData = {
          room: room,
          like: data,
        };
        socket.emit("send_likes", likeData);
      }
    },
    changePageItems: (items) => {
      
        setPageItems(items)
    },
    clearSelected: () =>{
        setSelected([])
    },
    changeMaterialUsed: (value) => {
      setMaterialUsed([...materialUsed, ...value]);
    },
    itemData,
    changeItemData: (value) => {
      setItemData(value);
    },
  };

  const materialIds =
    "194,128,196,4019,1032,3535,2747,4176,4149,4169,795,1014,2942,1126,912,3463,3541,4066,1010,1005,4029,3571,3999,1057,447,1018,2938,1022,2947,2348,1262,1883,1946,1915,1188,1716,809,4253,4183,3822,4272,3977";

  function getData() {
    fetch(`https://matlib.co/wp-json/ml/v1/byId?ids=${materialIds}`)
      .then((response) => response.json())
      .then((data) => setMaterialData(data));
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const messageData = {
        room: room,
        message: `Page ${currentSlide}`,
        count: currentSlide,
        items: pageItems,
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
      };
  
      socket.emit("send_message", messageData);
      // eslint-disable-next-line
  }, [currentSlide, pageItems]);

  return (
    <MaterialContext.Provider value={value}>
      {props.children}
    </MaterialContext.Provider>
  );
};

export default MaterialContextProvider;
