import React from "react";
import { motion } from 'framer-motion'

const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1
      }
    }
  }
  
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1,
        transition: {
            staggerChildren: 0.5,
            delayChildren: 0.8
          }
    }
  }
  const draw = {
      hidden: { opacity: 0, pathLength: 0, },
      transition:{ duration: 2 },
      show: { opacity: 1, pathLength: 1,
      
    }
  }

const carbonGraphic = (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 750"
    width="600"
    height="500"
    variants={container}
    initial="hidden"
    animate="show"
    style={{filter:'drop-shadow(0 0 2px black)'}}
  >
    <g>
      <line
        stroke="white"
        strokeWidth="20"
        x1="648"
        y1="578"
        x2="367"
        y2="578"
      />
    </g>
    <g>
      <line
        stroke="white"
        strokeWidth="20"
        x1="723.7"
        y1="480.7"
        x2="573.3"
        y2="243.3"
      />
    </g>
    <g>
      <line
        stroke="white"
        strokeWidth="20"
        x1="292.1"
        y1="481.5"
        x2="442.5"
        y2="244.2"
      />
    </g>
    <g>
      <motion.path
        fill="white"  variants={draw} initial="hidden" animate="show"
        d="M245.5,477c56,0,101.5,45.5,101.5,101.5S301.5,680,245.5,680S144,634.5,144,578.5S189.5,477,245.5,477 M245.5,457
		C178.4,457,124,511.4,124,578.5S178.4,700,245.5,700S367,645.6,367,578.5S312.6,457,245.5,457L245.5,457z"
      />
    </g>
    <g>
      <motion.path
        fill="white" variants={draw} initial="hidden" animate="show"
        d="M769.5,476c56,0,101.5,45.5,101.5,101.5S825.5,679,769.5,679S668,633.5,668,577.5S713.5,476,769.5,476 M769.5,456
		C702.4,456,648,510.4,648,577.5S702.4,699,769.5,699S891,644.6,891,577.5S836.6,456,769.5,456L769.5,456z"
      />
    </g>
    <g>
      <motion.path
        fill="white" variants={draw} initial="hidden" animate="show"
        d="M507.5,58c56,0,101.5,45.5,101.5,101.5S563.5,261,507.5,261S406,215.5,406,159.5S451.5,58,507.5,58 M507.5,38
		C440.4,38,386,92.4,386,159.5S440.4,281,507.5,281S629,226.6,629,159.5S574.6,38,507.5,38L507.5,38z"
      />
    </g>
    <motion.path fill="white" variants={item}
      d="M565.2,177.7h-3v-20.5c0-2.4-1-4.7-2.7-6.4l-18.9-18.9c-1.7-1.7-4-2.7-6.4-2.7h-8.4v-9.1c0-5-4.1-9.1-9.1-9.1h-60.7
	c-5,0-9.1,4.1-9.1,9.1v60.7c0,5,4.1,9.1,9.1,9.1h3c0,10.1,8.2,18.2,18.2,18.2s18.2-8.2,18.2-18.2h24.3c0,10.1,8.2,18.2,18.2,18.2
	s18.2-8.2,18.2-18.2h9.1c1.7,0,3-1.4,3-3v-6.1C568.2,179.1,566.8,177.7,565.2,177.7z M477.2,198.9c-5,0-9.1-4.1-9.1-9.1
	c0-5,4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1C486.3,194.9,482.2,198.9,477.2,198.9z M537.8,198.9c-5,0-9.1-4.1-9.1-9.1c0-5,4.1-9.1,9.1-9.1
	s9.1,4.1,9.1,9.1C546.9,194.9,542.9,198.9,537.8,198.9z M553,159.5h-27.3v-21.2h8.4l18.9,18.9V159.5z"
    />
    <motion.path fill="white" variants={item}
      d="M821,613.1l-28.3-45.5v-36.5h1.9c3.2,0,5.8-2.6,5.8-5.8v-3.9c0-3.2-2.6-5.8-5.8-5.8h-50.2c-3.2,0-5.8,2.6-5.8,5.8v3.9
	c0,3.2,2.6,5.8,5.8,5.8h1.9v36.5L718,613.1c-7.1,11.4,1.1,26.2,14.5,26.2h74C819.9,639.3,828.1,624.5,821,613.1z M748.7,593
	l11.6-18.7c0.9-1.3,1.4-2.8,1.4-4.4v-38.6h15.5v38.6c0,1.7,0.5,3.2,1.4,4.4l11.6,18.7H748.7z"
    />
    <motion.path fill="white" variants={item}
      d="M245.3,551.1c-15.3,0-27.7,12.4-27.7,27.7s12.4,27.7,27.7,27.7c15.3,0,27.7-12.4,27.7-27.7S260.6,551.1,245.3,551.1z
	 M316.4,574.4l-27.3-13.6l9.7-29c1.3-3.9-2.4-7.6-6.3-6.3l-29,9.7l-13.7-27.3c-1.8-3.7-7.1-3.7-8.9,0l-13.6,27.3l-29-9.7
	c-3.9-1.3-7.6,2.4-6.3,6.3l9.7,29l-27.3,13.7c-3.7,1.8-3.7,7.1,0,8.9l27.3,13.6l-9.7,29c-1.3,3.9,2.4,7.6,6.3,6.3l29-9.7l13.6,27.3
	c1.8,3.7,7.1,3.7,8.9,0l13.6-27.3l29,9.7c3.9,1.3,7.6-2.4,6.3-6.3l-9.7-29l27.3-13.6C320.1,581.5,320.1,576.2,316.4,574.4
	L316.4,574.4z M271.4,604.9c-14.4,14.4-37.8,14.4-52.2,0c-14.4-14.4-14.4-37.8,0-52.2c14.4-14.4,37.8-14.4,52.2,0
	C285.8,567.1,285.8,590.5,271.4,604.9z"
    />
  </motion.svg>
);

function Carbon() {
  return <div>{carbonGraphic}</div>;
}

export default Carbon;
