import React from "react";

export const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

export const circleIcon = (
  <svg
    className="circle-loader"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    width="60"
    height="60"
  >
    <path
      style={{ fill: "#E11B4E" }}
      d="M39,20c0,10.5-8.5,19-19,19v1c11,0,20-9,20-20H39z"
    />
    <path
      style={{ fill: "#686868" }}
      d="M20,1c10.5,0,19,8.5,19,19h1C40,9,31,0,20,0l0,0C9,0,0,9,0,20s9,20,20,20v-1C9.5,39,1,30.5,1,20S9.5,1,20,1"
    />
  </svg>
);

export const questionIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
      clipRule="evenodd"
    />
  </svg>
);

export const arrayIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <g>
      <path d="M10.5,7H0.8C0.4,7,0,6.6,0,6.2V0.8C0,0.4,0.4,0,0.8,0h9.7c0.4,0,0.8,0.4,0.8,0.8v5.4C11.3,6.6,10.9,7,10.5,7z" />
    </g>
    <g>
      <path
        d="M23.2,7h-9.7c-0.4,0-0.8-0.4-0.8-0.8V0.8c0-0.4,0.4-0.8,0.8-0.8h9.7C23.6,0,24,0.4,24,0.8v5.4
		C24,6.6,23.6,7,23.2,7z"
      />
    </g>
    <g>
      <path
        d="M10.5,15.6H0.8c-0.4,0-0.8-0.4-0.8-0.8V9.4c0-0.4,0.4-0.8,0.8-0.8h9.7c0.4,0,0.8,0.4,0.8,0.8v5.4
		C11.3,15.2,10.9,15.6,10.5,15.6z"
      />
    </g>
    <g>
      <path
        d="M23.2,15.6h-9.7c-0.4,0-0.8-0.4-0.8-0.8V9.4c0-0.4,0.4-0.8,0.8-0.8h9.7c0.4,0,0.8,0.4,0.8,0.8v5.4
		C24,15.2,23.6,15.6,23.2,15.6z"
      />
    </g>
    <g>
      <path
        d="M10.5,24H0.8C0.4,24,0,23.6,0,23.2v-5.4C0,17.4,0.4,17,0.8,17h9.7c0.4,0,0.8,0.4,0.8,0.8v5.4
		C11.3,23.6,10.9,24,10.5,24z"
      />
    </g>
    <g>
      <path
        d="M23.2,24h-9.7c-0.4,0-0.8-0.4-0.8-0.8v-5.4c0-0.4,0.4-0.8,0.8-0.8h9.7c0.4,0,0.8,0.4,0.8,0.8v5.4
		C24,23.6,23.6,24,23.2,24z"
      />
    </g>
  </svg>
);