import React, { useEffect } from "react";
import { useCurrentSlide } from "../context/CurrentSlideContext";
import { motion } from "framer-motion";

export const Transition = ({ children, order, duration }) => {
  
  const { currentStep, steps, addStep, removeStep } = useCurrentSlide();
  useEffect(() => {
    if (!steps.includes(order)) {
      addStep(order);
    }
  }, [order, addStep, removeStep, steps]);

  const stepIndex = steps.findIndex((step) => step === order);
  const isVisible = stepIndex === currentStep;
  const opacity = isVisible ? 1 : 0;
  const newDuration = duration ? duration : 0;

  console.log(newDuration)

  return (
    <motion.div
      className="transition"
      animate={{ opacity }}
      transition={{ duration: newDuration }}
      initial={{ opacity: 0}}
    >
      {children}
    </motion.div>
  );
};

export default Transition;