
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.705,"time":102300,"words":341}
/* @jsxRuntime classic */
/* @jsx mdx */
import MainLayout from "./layouts/Main";
import KindLayout from "./layouts/Kind";
import SlidePage from "./layouts/SlidePage";
import MaterialsPage from "./components/MaterialsPage";
import MaterialModal from "./components/MaterialModal";
import Grow from "./animation/Grow";
import Testing from "./animation/Testing";
import MaterialDisplay from "./components/MaterialDisplay";
import InformationDisplay from "./components/InformationDisplay";

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Steps = makeShortcode("Steps");
const Step = makeShortcode("Step");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SlidePage mdxType="SlidePage">
      <MainLayout slide="intro" mdxType="MainLayout">
        <h1 {...{
          "id": "jordan-x-asiatan"
        }}>{`Jordan x Asiatan`}</h1>
        <h2 {...{
          "id": "fall-23"
        }}>{`FALL 23`}</h2>
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <h1 style={{
          color: '#313131'
        }}>Presentation Overview</h1>
        <Steps mdxType="Steps">
  <Step mdxType="Step"><h2>Core</h2></Step>
  <Step mdxType="Step"><h2>Explore</h2></Step>
  <Step mdxType="Step"><h2>Kind Leather 2.0</h2></Step>
  <Step mdxType="Step"><h2>TecTuff</h2></Step>
        </Steps>
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <h1 style={{
          color: "#313131"
        }}>Core</h1>
        <h4 style={{
          color: "#FEFEFE"
        }}>Go-To Leathers & Material Updates</h4>
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialDisplay title="Core Leathers" slideData={["stealth-rave", "antique-rave", "rave"]} mdxType="MaterialDisplay" />
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialDisplay title="Core Leathers" slideData={["matador", "kind-stadia", "glacier", "avalanche"]} mdxType="MaterialDisplay" />
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <h1 style={{
          color: "#313131"
        }}>EXPLORE</h1>
        <Steps mdxType="Steps">
  <Step mdxType="Step"></Step>
  <Step mdxType="Step">
    <h4 style={{
              color: "#FEFEFE"
            }}>Jordan Specific</h4>
    <h4 style={{
              color: "#FEFEFE"
            }}>Sport Vintage</h4>
    <h4 style={{
              color: "#FEFEFE"
            }}>Rumpled Nubucks</h4>
    <h4 style={{
              color: "#FEFEFE"
            }}>Slubby Suedes</h4>
    <h4 style={{
              color: "#FEFEFE"
            }}>Deep Deboss</h4>
  </Step>
        </Steps>
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialDisplay title="Jordan Specific" slideData={["hand-stained-bark-washed", "tipped-mcr-matador", "glazed-glacier", "pearlized-matador"]} mdxType="MaterialDisplay" />
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialDisplay title="Sport Vintage" slideData={["two-tone-parchment-crush", "hand-stained-croco-washed", "stealth-quake-glory", "quake-buck"]} mdxType="MaterialDisplay" />
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialDisplay title="Rumpled Nubucks" slideData={["aged-rave", "magma-buck", "dune-washed"]} mdxType="MaterialDisplay" />
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialDisplay title="Slubby Suedes" slideData={["vintage-suede", "vintage-flip-flop", "antique-flip-flop", "jeans-flip-flop"]} mdxType="MaterialDisplay" />
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialDisplay title="Deep Deboss" slideData={["geo-nubuck", "geo-tri-buck", "geo-waffle-buck", "geo-hex-washed"]} mdxType="MaterialDisplay" />
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <h1 style={{
          color: "#313131"
        }}>GOAT LEATHER</h1>
        <Steps mdxType="Steps">
  <Step mdxType="Step"></Step>
  <Step mdxType="Step">
    <h4 style={{
              color: "#FEFEFE"
            }}>Environmental Benefits</h4>
  </Step>
  <Step mdxType="Step">
    <h4 style={{
              color: "#FEFEFE"
            }}>Light-weight & Strong</h4>
  </Step>
        </Steps>
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialDisplay title="Goat Classics" slideData={["kid-glove-charm", "kid-stadia", "kid-brilliant", "kid-micro-stadia"]} mdxType="MaterialDisplay" />
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialModal title="Wrinkle Patent" slideData={["brilliant", "brilliant-rave", "kid-brilliant", "crumple-brilliant", "metallic-quake-brilliant"]} mdxType="MaterialModal" />
      </MainLayout>
      <hr></hr>
      <KindLayout mdxType="KindLayout">
        <h1 style={{
          color: "#333"
        }}> Kind Leather 2.0 </h1>
        <Steps mdxType="Steps">
  <Step mdxType="Step">
    <h3>We have progressed a long way from where we started.</h3>
  </Step>
  <Step mdxType="Step">
    <h4>the next phase...</h4>
  </Step>
  <Step mdxType="Step">
    <h3 className="no-to-waste"> Say No to Waste!</h3>
  </Step>
   <Step mdxType="Step">
    <h4> Even MORE waste</h4>
  </Step>
        </Steps>
      </KindLayout>
      <hr></hr>
      <KindLayout mdxType="KindLayout">
        <InformationDisplay mdxType="InformationDisplay" />
      </KindLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <h1 {...{
          "id": "tectuff"
        }}>{`TecTuff`}</h1>
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialDisplay title="TecTuff Textures" slideData={["moire", "marvel", "3d-carbone", "fibreglass"]} mdxType="MaterialDisplay" />
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <MaterialDisplay title="TecTuff Textures" slideData={["bodie", "stucco", "phant"]} mdxType="MaterialDisplay" />
      </MainLayout>
      <hr></hr>
      <MainLayout mdxType="MainLayout">
        <h1 {...{
          "id": "thanks"
        }}>{`Thanks!`}</h1>
      </MainLayout>
    </SlidePage>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "fall-23",
    level: 2,
    title: "FALL 23",
    children: [
        
      ]
  }
]

export const frontMatter = {}

