import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { MaterialContext } from "../context/MaterialContext";
import Search from './Search'
import Modal from './Modal'


function MaterialsList() {

  const { materialData, materialUsed, changeItemData } = useContext(MaterialContext);

  function renderTableData() {
    return materialData.map((data, index) => {
      const { id, title, slug, description, price } = data; //destructuring
      return (
        <tr key={id} onClick={() => handleItem(data)} >
          <td>{index+1}</td>
          <td><h5>{id}</h5></td>
          <td><h5>{title.toUpperCase()}</h5></td>
          <td><h5>{slug}</h5></td>
          <td><h5>{description}</h5></td>
          <td><h5>${price}</h5></td>
          <td><h5>{materialUsed.includes(id) ? 'used' : 'not used'}</h5></td>
        </tr>
      );
    });
  }

  const handleItem = (data) => {
    changeItemData(data)
  }

  return (
    <>
    <div className="info-container">
      <div className="info-body">
        <div className="info-body-columns">
          <div className="column-one">
            <Link to={"/directions"}>Directions</Link>
          </div>
          <div className="column-two">
            <table style={{width:'100%'}}>
              <tbody>
                <tr style={{background:'white', color:'black', fontSize:'16px', textTransform:'uppercase'}}>
                  <td style={{ width: "20px", padding:'0 5px' }}><h5>#</h5></td>
                  <td style={{ width: "30px", padding:'0 5px' }}><h5>ID</h5></td>
                  <td style={{ width: "160px", padding:'0 5px' }}><h5>Title</h5></td>
                  <td style={{ width: "100px", padding:'0 5px' }}><h5>Slug</h5></td>
                  <td style={{ width: "260px",  padding:'0 5px'}}><h5>Description</h5></td>
                  <td style={{ width: "60px",  padding:'0 5px'}}><h5>Price</h5></td>
                  <td style={{ width: "60px",  padding:'0 5px'}}><h5>Used</h5></td>
                </tr>
                {materialData && renderTableData()}
              </tbody>
            </table>
          </div>
          <div className="column-three">
          <Search/>
         
          </div>
        </div>
      </div>
      <div className="directions-footer">
        <p>Data compiled from Matlib</p>
      </div>
     
    </div>
    <Modal/>
    </>
  );
}

export default MaterialsList;
