import React from 'react'
import MDXFile from './content.mdx'
import MDXProvider from './components/MDXProvider';
import { CurrentSlideProvider } from './context/CurrentSlideContext';
import Footer from "./components/Footer"
import Header from "./components/Header"
import './App.css';
import './styles/custom.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import presentationData from './data/presentationData.json';
import Directions from './components/Directions';
import MaterialContextProvider from './context/MaterialContext'
import MaterialsList from './components/MaterialsList';
import Mobile from './components/Mobile';

import './fonts/GT-Walsheim/GT-Walsheim-Bold-subset.woff2'



function App() {



  const { presentationTitle } = presentationData[0];
  return (
    <MDXProvider>
       <CurrentSlideProvider>
        <Router>
        <MaterialContextProvider>
          <Header title={presentationTitle} />
            <div className="App">
           
            <Switch>
              <Route path="/directions" component={Directions} />
              
                <Route path="/materials" component={MaterialsList} /> 
                <Route path="/m/:slug" component={Mobile}/>
                <Route exact path="/" component={MDXFile} />
              
          </Switch>
            </div>
          <Footer/>
          </MaterialContextProvider>
        </Router>
      </CurrentSlideProvider>
    </MDXProvider>
  );
}

export default App;
